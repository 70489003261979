input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
  
.modal-dialog {
	margin-top: '50%';
}

.content-news img {
    width: 100%;
}

div::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	border-radius: 5px;
	background-color: #F5F5F5;
}

div::-webkit-scrollbar
{
	width: 5px;
	height: 5px;
	background-color: #F5F5F5;
}

div::-webkit-scrollbar-thumb
{
	border: 4px solid #208B31;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #208B31;
}

#__react-alert__ div div div {
	width: auto !important;
}
